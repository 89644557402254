import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Gracias por proporcionarnos más información</h1>
          <p>En breve nos pondremos en contacto contigo, mira tu correo electrónico para futuras comunicaciones.</p>
        </div>
      </div>
    </section>
  </Layout>
);
